import axios from 'axios'

import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,
  state: {
    workspaces: []
  },
  mutations: {
    ...makeSetters([
      'workspaces'
    ])
  },
  actions: {
    async loadPrerequisiteData ({commit}) {
      const [
        {data: permissions},
        {data: workspaces}
      ] = await Promise.all([
        axios.get('/api/v2/power-bi/permissions'),
        axios.get('/api/v2/power-bi/workspaces')
      ])

      commit('setUserPermissions', permissions, {root: true})
      commit('setWorkspaces', workspaces)
    }
  }
}
